import './style.css';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import fotocarnet from './imagenes/fotocarnet.png';


function SobreMi() {
    return(
        <Card  className="paddingPractice" style={{  width: '100%', border: 'none', borderRadius: '0'}}>
        <Card.Body>
          <Card.Title style={{fontFamily: 'notoSemibold', fontSize: '1.5em'}} id='sobremi'>Sobre mí</Card.Title>
          <Row xs={1} sm={1} md={2} lg={2}>
            <Col>
          <Card className="SobreMiContainer" style={{border: 'none', width: '100%', borderRadius: '0'}}>
            <Card.Text className="sobreMi">
            Mi nombre es Sofia Mato, soy geógrafa y hace tiempo que seguí estudiando diseño web, desarrollo web y programación. Mis intereses son la didáctica de la Geografía, que actualmente desempeño en la docencia, y el desarrollo de productos digitales, en lo cual comencé a formarme y deseo desarrollar.  
Realice mi primer trabajo de diseño y desarrollo web para una película documental, junto a una diseñadora gráfica lo que me ayudo a mejorar mi experiencia y conocimientos de diseño. 

            </Card.Text>
            </Card>
            </Col>
            <Col>
            <Card className="ImgContainer" style={{ border: 'none', borderRadius: '0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Card.Img className="ImgWidth" src={fotocarnet}></Card.Img>
            </Card>
            </Col>
            </Row>
        </Card.Body>
      </Card>
        
        
    );

}

export default SobreMi;
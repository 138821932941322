import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import eCommerce1 from './imagenes/eCommerce1.png';
import eCommerce5 from './imagenes/eCommerce5.png';
import eCommerce6 from './imagenes/eCommerce6.png';
import eCommerce7 from './imagenes/eCommerce7.png';
import ListGroup from 'react-bootstrap/ListGroup';
import arrow50 from './imagenes/plus.png';
import { useEffect } from 'react';
import Navigation from './NavBar'

function Ecommerce() {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <>
        <Navigation />
        <Card className="padding3rem" style={{ background: '#0a1a2f'}}>
        <Card.Title style={{ color: 'white', padding: '2rem', fontFamily: 'notoBold', fontSize: '1.5em'}} id='petgram'>Simulador de e - commerce</Card.Title>
        <Row className="padding2rem" xs={1} sm={1} md={1} lg={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Card.Img style={{width: '100%', borderRadius: '1.5%'}} src={eCommerce1}></Card.Img>
        </Row>
        <Row className='padding2rem' xs={1} sm={1} md={1} lg={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <ListGroup className='padding2rem' style={{ background: '#0a1a2f' }}>
        <ListGroup.Item style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem', color: 'white', background: '#0a1a2f'}}> <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/>Creación de lista de productos</ListGroup.Item>
        <ListGroup.Item style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem', color: 'white', background: '#0a1a2f'}}><Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/>Creación de categorías</ListGroup.Item>
        <ListGroup.Item style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem', color: 'white', background: '#0a1a2f'}}><Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/>Acceso al carrito de compras desde cualquier página de la aplicación</ListGroup.Item>
        </ListGroup>
        </Row>
        <Row className='padding2rem' xs={1} sm={1} md={3} lg={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Col style={{padding: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Card.Img style={{width: '90%'}} src={eCommerce5}></Card.Img>
        </Col>
        <Col style={{padding: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Card.Img style={{width: '90%'}} src={eCommerce6}></Card.Img>
        </Col>
        <Col style={{padding: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Card.Img style={{width: '90%'}} src={eCommerce7}></Card.Img>
        </Col>
        </Row>
        <Row className='padding2rem' xs={1} sm={1} md={1} lg={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <ListGroup className='padding2rem' style={{ background: '#0a1a2f' }}>
        <ListGroup.Item style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem', color: 'white', background: '#0a1a2f'}}> <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/>Creación de alertas</ListGroup.Item>
        <ListGroup.Item style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem', color: 'white', background: '#0a1a2f'}}><Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/>Diseño totalmente adaptable a distintos dispositivos</ListGroup.Item>
        <ListGroup.Item style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem', color: 'white', background: '#0a1a2f'}}><Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/>Envío de datos de compra a base de datos</ListGroup.Item>
        </ListGroup>
        </Row>
       </Card>

       </>

    )
    
    }
    
    
    export default Ecommerce;
import './style.css';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DataFromBD from './ItemsCursos';
import Curso from './Curso';


function Courses() {


    return(
        <Card className='paddingPractice' key={2} style={{ width: '100%', border: 'none', background: '#0a1a2f', borderRadius: '0' }}>
        <Card.Body>
        <Card.Title style={{ color: 'white',  fontFamily: 'notoSemibold', fontSize: '1.5em'}} id='courses'>Courses</Card.Title>
        <Row xs={2} sm={2} md={3} lg={5} style={{marginTop: '2rem', textAlign: 'center'}} className="g-4">
    {
    
    DataFromBD.map(item => ( 
      
      <Col key={item.id} className="columna-4">
       <Curso item={item}/> 
      </Col>
      
    
    )) 
    
    } 
    </Row>
        </Card.Body>
      </Card>
        
        
    );

}

export default Courses;
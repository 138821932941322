import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import apollo from './imagenes/apollo.png';
import petgram1 from './imagenes/petgram1.png';
import petgram2 from './imagenes/petgram2.png';
import petgram3 from './imagenes/petgram3.png';
import petgram4 from './imagenes/petgram4.png';
import ListGroup from 'react-bootstrap/ListGroup';
import arrow50 from './imagenes/plus.png';
import { useEffect } from 'react';
import NavigationEnglish from './NavBarEnglish'

function PetAppEnglish() {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <>
        <NavigationEnglish />
        <Card className="padding3rem" style={{ background: '#0a1a2f'}}>
        <Card.Title style={{ color: 'white', padding: '2rem', fontFamily: 'notoBold', fontSize: '1.5em'}} id='petgram'>Petgram</Card.Title>
        <Row className='padding2rem' xs={1} sm={1} md={2} lg={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Col style={{padding: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Card.Img className="Img3" src={petgram2}></Card.Img>
        </Col>
        <Col style={{padding: '1rem'}}>
        <ListGroup className='padding2rem' style={{ background: '#0a1a2f' }}>
        <ListGroup.Item style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem', color: 'white', background: '#0a1a2f'}}> <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/>Develop an app with integrated server-client functions</ListGroup.Item>
        <ListGroup.Item style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem', color: 'white', background: '#0a1a2f'}}><Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/>Handling query and data mutation via ApolloNextAppProvider</ListGroup.Item>
        <ListGroup.Item style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem', color: 'white', background: '#0a1a2f'}}><Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/>Rendering dynamic pages and querying with apolloClient</ListGroup.Item>
        <ListGroup.Item style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem', color: 'white', background: '#0a1a2f'}}><Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/>User Login and SignUp</ListGroup.Item>
        <ListGroup.Item style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem', color: 'white', background: '#0a1a2f'}}><Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/>Integrated and dynamic session data handling</ListGroup.Item>
        <ListGroup.Item style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem', color: 'white', background: '#0a1a2f'}}><Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/>Added SEO with next/head component and create manifest file</ListGroup.Item>
        </ListGroup>
        </Col>
        </Row>
        <Row className='padding2rem' xs={1} sm={1} md={3} lg={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Col style={{padding: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Card.Img style={{width: '90%'}} src={petgram3}></Card.Img>
        </Col>
        <Col style={{padding: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Card.Img style={{width: '90%'}} src={petgram4}></Card.Img>
        </Col>
        <Col style={{padding: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Card.Img style={{width: '90%'}} src={petgram1}></Card.Img>
        </Col>
        </Row>
        <Row className='padding2rem' xs={1} sm={1} md={1} lg={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <ListGroup className='padding2rem' style={{ background: '#0a1a2f' }}>
        <ListGroup.Item style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem', color: 'white', background: '#0a1a2f'}}> <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/>Server side development with @apollo/server</ListGroup.Item>
        <ListGroup.Item style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem', color: 'white', background: '#0a1a2f'}}><Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/>Creation of dataSource, resolvers and schema</ListGroup.Item>
        <ListGroup.Item style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem', color: 'white', background: '#0a1a2f'}}><Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/>Server-side mutations and queries via Apollo Sandbox</ListGroup.Item>
        </ListGroup>
        </Row>
        <Row className="padding2rem" xs={1} sm={1} md={1} lg={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Card.Img style={{width: '100%', borderRadius: '1.5%'}} src={apollo}></Card.Img>
        </Row>
       </Card>

       </>

    )
    
    }
    
    
    export default PetAppEnglish;
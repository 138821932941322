import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';
import home24 from './imagenes/home24.png';
import mail24 from './imagenes/mail24.png';
import coder from './imagenes/coder.png';
import unlu from './imagenes/unlu.png';
import arrow50 from './imagenes/plus.png';
import plus from './imagenes/plusout.png';
import ux from './imagenes/UX.png';
import './style.css';
import Navigation from './NavBar';


function CurriculumCV() {


return (
    <>
<Navigation />
    <Row className="padding3rem" style={{ background: '#0a1a2f'}}>
        <Row>
        <Col>
        <Card.Title className="paddingPractice" style={{ color: 'white', fontFamily: 'notoBold', fontSize: 'calc(1.5em + 1vw + 1vh)'}}>Sofia Mato</Card.Title>
        <Card style={{ width: '100%', border: 'none', padding: '1rem', background: '#0a1a2f', borderRadius: '0' }}>
            <Card.Title style={{ color: 'white', fontFamily: 'notoSemibold', fontSize: '1.2rem'}}>Contacto:</Card.Title>
            <div key={0}>
            <ListGroup className="padding2rem">
                <ListGroup.Item style={{ color: 'white', background: 'transparent', fontFamily: 'notoSemiBold', fontSize: '1em'}}>
                <Card.Img style={{width: "1.4rem", marginRight: '0.5rem'}} src={home24}/>Ciudad Autónoma de Buenos Aires
                </ListGroup.Item>
                <ListGroup.Item style={{color: 'white', background: 'transparent',fontFamily: 'notoSemiBold', fontSize: '1em'}}>
                <Card.Img style={{width: "1.4rem", marginRight: '0.5rem'}} src={mail24}/>sofiamato30@gmail.com
                    </ListGroup.Item>
            </ListGroup>
            </div>
        </Card>
        <Card style={{ width: '100%', border: 'none', padding: '1rem', background: '#0a1a2f', borderRadius: '0' }}>
            <Card.Title style={{ color: 'white', fontFamily: 'notoSemibold', fontSize: '1.2rem'}}>Experiencia laboral:</Card.Title>
            <div key={3}>
            <ListGroup  className="padding2rem">
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1em'}}>
                <Card.Img style={{width: "1.2rem", marginRight: '0.5rem'}} src={ux}/>  Desarrollo Web Freelance ( 2022 - 2022 ) Película documental - Landing Page
                </ListGroup.Item>
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1em'}}>
                <Card.Img style={{width: "1.2rem", marginRight: '0.5rem'}} src={ux}/>  Desarrollo Web Freelance ( 2023 - 2023 ) E - commerce - Plataforma Tienda Nube
                </ListGroup.Item>
            </ListGroup>
            </div>
        </Card>
        
        </Col>
        <Col>
        <Card style={{ width: '100%', border: 'none', padding: '1rem', background: '#0a1a2f', borderRadius: '0' }}>
        <Card.Title style={{ color: 'white', fontFamily: 'notoSemibold', fontSize: '1.2rem'}}>Formación académica:</Card.Title>
        <div key={2}>
        <ListGroup className="padding2rem">
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1rem'}}>
                <Card.Img style={{width: "2.6rem", marginRight: '0.5rem'}} src={coder}/> Carrera de Desarrollo Front End React 
                </ListGroup.Item>
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1rem'}}>
                <Card.Img style={{width: "2.6rem", marginRight: '0.5rem'}} src={coder}/>  Carrera de Desarrollo UX/UI 
                </ListGroup.Item>
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1rem'}}>
                <Card.Img style={{width: "2.6rem", marginRight: '0.5rem'}} src={unlu}/> Licenciatura en Geografía UNLU
                </ListGroup.Item>
                
            
            </ListGroup>

        </div>
        </Card>
        <Card style={{ width: '100%', border: 'none', padding: '1rem', background: '#0a1a2f', borderRadius: '0' }}>
        <Card.Title style={{ color: 'white', fontFamily: 'notoSemibold', fontSize: '1.2rem'}}>Aptitudes:</Card.Title>
        <div key={1}>
        <ListGroup className="padding2rem">
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1em'}}>
                <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/> Capacidad organizativa y cooperativa
                </ListGroup.Item>
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1em'}}>
                <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/>  Creación de diseños originales
                </ListGroup.Item>
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1em'}}>
                <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/> Conocimientos de SEO
                    </ListGroup.Item>
                    <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1em'}}>
                <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/> Conocimientos de GraphQl
                    </ListGroup.Item>
                    <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1em'}}>
                <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/> Conocimientos de FireBase 
                    </ListGroup.Item>
                    <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1em'}}>
                <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/> Conocimientos de TypeScript
                    </ListGroup.Item>
                    <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1em'}}>
                <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/> Conocimientos de Javascript
                    </ListGroup.Item>
                    <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1em'}}>
                <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/> Conocimientos de NextJS
                    </ListGroup.Item>
                    <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1em'}}>
                    <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/> Vocación por la tecnología
                </ListGroup.Item>
            
            </ListGroup>
        </div>
        </Card>
        </Col>

        </Row>

        <Row>
        <Col>
        <Card style={{ width: '100%', border: 'none', padding: '1rem', background: '#0a1a2f', borderRadius: '0' }}>
            <Card.Title style={{color: 'white', fontFamily: 'notoSemibold', fontSize: '1.2rem'}}>Idiomas:</Card.Title>
            <div key={4}>

            <ListGroup className="padding2rem">
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1rem'}}>
                    Español: Nativo
                </ListGroup.Item>
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1rem'}}>
                    Inglés: Bilingüe 
                </ListGroup.Item>
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1rem'}}>
                    Portugués: Comprensión basica
                </ListGroup.Item>
          
            </ListGroup>


            </div>
        </Card>
        </Col>

        <Col>
        <Card style={{ width: '100%', border: 'none', padding: '1rem', background: '#0a1a2f', borderRadius: '0' }}>
        <Card.Title style={{ color: 'white', fontFamily: 'notoSemibold', fontSize: '1.2rem'}}>Certificaciones:</Card.Title>
        <div key={5}>
        <ListGroup className="padding2rem">
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1rem'}}>
                <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={plus}/> QGIS III
                </ListGroup.Item>
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1rem'}}>
                <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={plus}/> Diseño web
                </ListGroup.Item>
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1rem'}}>
                <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={plus}/>  Desarrollo Web
                </ListGroup.Item>
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1rem'}}>
                <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={plus}/> Javascript
                </ListGroup.Item>
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1rem'}}>
                <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={plus}/>  React JS 
                </ListGroup.Item>
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1rem'}}>
                <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={plus}/>  React JS Avanzado
                </ListGroup.Item>
         
          
            </ListGroup>
        </div>
        </Card>
        
        </Col>

        </Row>

    


    </Row>
    </>


)

}


export default CurriculumCV;
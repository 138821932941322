import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import eCommerce1 from './imagenes/eTravel1.png';
import eCommerce2 from './imagenes/eTravel2.png';
import eCommerce5 from './imagenes/eTravel5.png';
import eCommerce6 from './imagenes/eTravel6.png';
import eCommerce7 from './imagenes/eTravel4.png';
import ListGroup from 'react-bootstrap/ListGroup';
import arrow50 from './imagenes/plus.png';
import { useEffect } from 'react';
import Navigation from './NavBar'

function Etravel() {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <>
        <Navigation />
        <Card className="padding3rem" style={{ background: '#0a1a2f'}}>
        <Card.Title style={{ color: 'white', padding: '2rem', fontFamily: 'notoBold', fontSize: '1.5em'}} id='petgram'>Simulador de cotizador</Card.Title>
        <Row className="padding2rem" xs={1} sm={1} md={1} lg={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Card.Img style={{width: '100%', borderRadius: '1.5%'}} src={eCommerce1}></Card.Img>
        </Row>
        <Row className='padding2rem' xs={1} sm={1} md={1} lg={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <ListGroup className='padding2rem' style={{ background: '#0a1a2f' }}>
        <ListGroup.Item style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem', color: 'white', background: '#0a1a2f'}}> <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/>Cotización por regiones</ListGroup.Item>
        <ListGroup.Item style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem', color: 'white', background: '#0a1a2f'}}><Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/>Cotización según tipo de moneda</ListGroup.Item>
        <ListGroup.Item style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem', color: 'white', background: '#0a1a2f'}}><Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/>Elección de tipo de seguro según cobertura</ListGroup.Item>
        </ListGroup>
        </Row>
        <Row className="padding2rem" xs={1} sm={1} md={1} lg={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Card.Img style={{width: '100%', borderRadius: '1.5%'}} src={eCommerce2}></Card.Img>
        </Row>
        <Row className='padding2rem' xs={1} sm={1} md={1} lg={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <ListGroup className='padding2rem' style={{ background: '#0a1a2f' }}>
        <ListGroup.Item style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem', color: 'white', background: '#0a1a2f'}}> <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/>Creación de alertas</ListGroup.Item>
        <ListGroup.Item style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem', color: 'white', background: '#0a1a2f'}}><Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/>Diseño totalmente adaptable a distintos dispositivos</ListGroup.Item>
        <ListGroup.Item style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem', color: 'white', background: '#0a1a2f'}}><Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/>Creación de documento final en PDF con datos de cobertura y pasajero</ListGroup.Item>
        </ListGroup>
        </Row>
        <Row className='padding2rem' xs={1} sm={1} md={3} lg={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Col style={{padding: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Card.Img style={{width: '90%'}} src={eCommerce5}></Card.Img>
        </Col>
        <Col style={{padding: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Card.Img style={{width: '90%'}} src={eCommerce6}></Card.Img>
        </Col>
        <Col style={{padding: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Card.Img style={{width: '90%'}} src={eCommerce7}></Card.Img>
        </Col>
        </Row>
       </Card>

       </>

    )
    
    }
    
    
    export default Etravel;
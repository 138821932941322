import './style.css';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import React from 'react';
//import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function Practicas() {
  // const [showDiv, setShowDiv] = useState(false);
  // const [showDiv2, setShowDiv2] = useState(false);
  // const [showDiv3, setShowDiv3] = useState(false);

  // useEffect(() => {
  //   const onScroll = () => {
  //     const newShowDiv = window.scrollY > 700
  //     showDiv !== newShowDiv && setShowDiv(newShowDiv);
  //   };
  //   window.addEventListener('scroll', onScroll);
  //   return () => {
  //     window.removeEventListener('scroll', onScroll);
  //   };
  // }, [showDiv]);

  // useEffect(() => {
  //   const onScroll = () => {
  //     const newShowDiv2 = window.scrollY > 1000
  //     showDiv2 !== newShowDiv2 && setShowDiv2(newShowDiv2);
  //   };
  //   window.addEventListener('scroll', onScroll);
  //   return () => {
  //     window.removeEventListener('scroll', onScroll);
  //   };
  // }, [showDiv2]);

  // useEffect(() => {
  //   const onScroll = () => {
  //     const newShowDiv3 = window.scrollY > 1400
  //     showDiv3 !== newShowDiv3 && setShowDiv3(newShowDiv3);
  //   };
  //   window.addEventListener('scroll', onScroll);
  //   return () => {
  //     window.removeEventListener('scroll', onScroll);
  //   };
  // }, [showDiv3]);

  const CardList = () => {
    return (
      <Card className="typing-effect" style={{border: 'none', width: '100%', background: '#0a1a2f', padding: '3rem', borderRadius: '0'}}>
        <Card.Body className="typed-text">
      <Card.Text style={{lineHeight: '2rem', fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify'}}>Create React App</Card.Text>
      <Card.Text style={{lineHeight: '2rem', fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify'}}>Material Design</Card.Text>
      <Card.Text style={{lineHeight: '2rem', fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify'}}>ReactJS</Card.Text>
      <Card.Text style={{lineHeight: '2rem', fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify'}}>Javascript</Card.Text>
      </Card.Body>
      </Card>
    )
  }


  const CardList2 = () => {
    return (
      <Card className="typing-effect2" style={{border: 'none', width: '100%', background: '#0a1a2f', padding: '3rem', borderRadius: '0'}}>
      <Card.Body className="typed-text">
      <Card.Text style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem'}}>Create React App</Card.Text>
      <Card.Text style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem'}}>Bootstrap</Card.Text>
      <Card.Text style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem'}}>ReactJS</Card.Text>
      <Card.Text style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem'}}>Javascript</Card.Text>
      <Card.Text style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem'}}>FireBase</Card.Text>
      </Card.Body>
      </Card>
    )
  }

  const CardList3 = () => {
    return (
      <Card className="typing-effect" style={{border: 'none', background: '#0a1a2f', width: '100%', padding: '3rem', borderRadius: '0'}}>
       <Card.Body className="typed-text">
      <Card.Text style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem'}}>NextJS</Card.Text>
      <Card.Text style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem'}}>Integración server - client</Card.Text>
      <Card.Text style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem'}}>styled-components</Card.Text>
      <Card.Text style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem'}}>Apollo graphQL</Card.Text>
      <Card.Text style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem'}}>Typescript</Card.Text>
      <Card.Text style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem'}}>Javascript</Card.Text>
      </Card.Body>
      </Card>
    )
  }
    return(
        <Card className="paddingPractice" style={{ width: '100%', border: 'none', background: '#0a1a2f', borderRadius: '0' }}>
        <Card.Body>
          <Row xs={1} sm={2} md={2} lg={2}>
          <Col style={{marginBottom: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Link to='/etravel'><Button variant="light" className="sitio">Cotizador</Button></Link>
          </Col>
          <Col style={{marginBottom: '1rem'}}>
            {/* {showDiv && <CardList /> } */}
            <CardList />
          </Col>
          </Row>
          <Row xs={1} sm={2} md={2} lg={2}>
          <Col style={{marginBottom: '1rem'}}>
          {/* {showDiv2 && <CardList2 />} */}
          <CardList2 />
          </Col>
          <Col style={{marginBottom: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Link to='/ecommerce'><Button variant="light" className="sitio">E - commerce</Button></Link>
          </Col>
          </Row>
          <Row xs={1} sm={2} md={2} lg={2}>
          <Col style={{marginBottom: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Link to='/petapp'><Button variant="light" className="sitio">Like Pet App</Button></Link>
          </Col>
          <Col style={{marginBottom: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          {/* {showDiv3 && <CardList3 />} */}
          <CardList3 />
          </Col>
          </Row>
        </Card.Body>
        </Card>
    )

}

export default Practicas;

       
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';
import home24 from './imagenes/home24.png';
import mail24 from './imagenes/mail24.png';
import coder from './imagenes/coder.png';
import unlu from './imagenes/unlu.png';
import arrow50 from './imagenes/plus.png';
import plus from './imagenes/plusout.png';
import ux from './imagenes/UX.png';
import './style.css';
import NavigationEnglish from './NavBarEnglish';


function Resume() {

return (
    <>
<NavigationEnglish />
    <Row className="padding3rem" style={{ background: '#0a1a2f'}}>
        <Row>
        <Col>
        <Card.Title className="paddingPractice" style={{ color: 'white', fontFamily: 'notoBold', fontSize: 'calc(1.5em + 1vw + 1vh)'}}>Sofia Mato</Card.Title>
        <Card style={{ width: '100%', border: 'none', padding: '1rem', background: '#0a1a2f', borderRadius: '0' }}>
            <Card.Title style={{ color: 'white', fontFamily: 'notoSemibold', fontSize: '1.2rem'}}>Contact:</Card.Title>
            <div key={0}>
            <ListGroup className="padding2rem">
                <ListGroup.Item style={{ color: 'white', background: 'transparent', fontFamily: 'notoSemiBold', fontSize: '1em'}}>
                <Card.Img style={{width: "1.4rem", marginRight: '0.5rem'}} src={home24}/>City of Buenos Aires
                </ListGroup.Item>
                <ListGroup.Item style={{color: 'white', background: 'transparent',fontFamily: 'notoSemiBold', fontSize: '1em'}}>
                <Card.Img style={{width: "1.4rem", marginRight: '0.5rem'}} src={mail24}/>sofiamato30@gmail.com
                    </ListGroup.Item>
            </ListGroup>
            </div>
        </Card>
        <Card style={{ width: '100%', border: 'none', padding: '1rem', background: '#0a1a2f', borderRadius: '0' }}>
            <Card.Title style={{ color: 'white', fontFamily: 'notoSemibold', fontSize: '1.2rem'}}>Work experience:</Card.Title>
            <div key={3}>
            <ListGroup  className="padding2rem">
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1em'}}>
                <Card.Img style={{width: "1.2rem", marginRight: '0.5rem'}} src={ux}/> Web design and development Freelance ( 2022 - 2022 ) Documentary film - Landing Page
                </ListGroup.Item>
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1em'}}>
                <Card.Img style={{width: "1.2rem", marginRight: '0.5rem'}} src={ux}/> Web design and development Freelance ( 2023 - 2023 ) E - commerce - Tienda Nube Platform
                </ListGroup.Item>
   
            </ListGroup>
            </div>
        </Card>
        
        </Col>
        <Col>
        <Card style={{ width: '100%', border: 'none', padding: '1rem', background: '#0a1a2f', borderRadius: '0' }}>
        <Card.Title style={{ color: 'white', fontFamily: 'notoSemibold', fontSize: '1.2rem'}}>Studies:</Card.Title>
        <div key={2}>
        <ListGroup className="padding2rem">
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1rem'}}>
                <Card.Img style={{width: "2.6rem", marginRight: '0.5rem'}} src={coder}/>Front End React Development Career
                </ListGroup.Item>
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1rem'}}>
                <Card.Img style={{width: "2.6rem", marginRight: '0.5rem'}} src={coder}/>Development UX/UI Career
                </ListGroup.Item>
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1rem'}}>
                <Card.Img style={{width: "2.6rem", marginRight: '0.5rem'}} src={unlu}/>Geography Degree National University of Lujan
                </ListGroup.Item>
                
            
            </ListGroup>

        </div>
        </Card>
        <Card style={{ width: '100%', border: 'none', padding: '1rem', background: '#0a1a2f', borderRadius: '0' }}>
        <Card.Title style={{ color: 'white', fontFamily: 'notoSemibold', fontSize: '1.2rem'}}>Skills:</Card.Title>
        <div key={1}>
        <ListGroup className="padding2rem">
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1em'}}>
                <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/> Organizational and cooperative capacity
                </ListGroup.Item>
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1em'}}>
                <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/>  Creation of original designs
                </ListGroup.Item>
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1em'}}>
                <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/> SEO knowledge
                    </ListGroup.Item>
                    <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1em'}}>
                <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/> GraphQl knowledge
                    </ListGroup.Item>
                    <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1em'}}>
                <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/> FireBase knowledge 
                    </ListGroup.Item>
                    <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1em'}}>
                <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/> TypeScript knowledge
                    </ListGroup.Item>
                    <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1em'}}>
                <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/> Javascript knowledge
                    </ListGroup.Item>
                    <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1em'}}>
                <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/> NextJS knowledge
                    </ListGroup.Item>
                    <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1em'}}>
                    <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/> Vocation for technology
                </ListGroup.Item>
            
            </ListGroup>
        </div>
        </Card>
        </Col>

        </Row>

        <Row>
        <Col>
        <Card style={{ width: '100%', border: 'none', padding: '1rem', background: '#0a1a2f', borderRadius: '0' }}>
            <Card.Title style={{color: 'white', fontFamily: 'notoSemibold', fontSize: '1.2rem'}}>Languages:</Card.Title>
            <div key={4}>

            <ListGroup className="padding2rem">
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1rem'}}>
                    Spanish: Native
                </ListGroup.Item>
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1rem'}}>
                    English: Bilingual
                </ListGroup.Item>
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1rem'}}>
                    Portuguese: Basic comprehension
                </ListGroup.Item>
          
            </ListGroup>


            </div>
        </Card>
        </Col>

        <Col>
        <Card style={{ width: '100%', border: 'none', padding: '1rem', background: '#0a1a2f', borderRadius: '0' }}>
        <Card.Title style={{ color: 'white', fontFamily: 'notoSemibold', fontSize: '1.2rem'}}>Certifications:</Card.Title>
        <div key={5}>
        <ListGroup className="padding2rem">
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1rem'}}>
                <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={plus}/> QGIS III
                </ListGroup.Item>
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1rem'}}>
                <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={plus}/> Web Desing
                </ListGroup.Item>
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1rem'}}>
                <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={plus}/> Web develoment
                </ListGroup.Item>
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1rem'}}>
                <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={plus}/> Javascript
                </ListGroup.Item>
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1rem'}}>
                <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={plus}/>  React JS 
                </ListGroup.Item>
                <ListGroup.Item style={{color: 'white', background: 'transparent', fontFamily: 'notoSemibold', fontSize: '1rem'}}>
                <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={plus}/> Advanced React JS
                </ListGroup.Item>
         
          
            </ListGroup>
        </div>
        </Card>
        
        </Col>

        </Row>

    


    </Row>
    </>


)

}


export default Resume;
import AlgunosTrabajos from './Algunostrabajos';
import Contacto from './Contacto';
import Cursos from './Cursos';
import SobreMi from './Sobremi';
import Practicas from './Practicas';
import Navigation from './NavBar';
import './style.css';



function Portada() {
    return(
        <div key={0}>
        <Navigation />
        <SobreMi/>
        <Cursos/>
        <Practicas />
        <AlgunosTrabajos/>
        <Contacto/>

        </div>
        
   
    );

}

export default Portada;
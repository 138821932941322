import './style.css';
import Card from 'react-bootstrap/Card';
import instagram from './imagenes/instagram.png';
import linkedin from './imagenes/linkedin.png';
import mail from './imagenes/email.png';
import otros from './imagenes/otros.png';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import React, { useState } from 'react';




function Contact() {

  const [showb, setShowb] = useState(false);

  const handleCloseb = () => setShowb(false);
  const handleShowb = () => setShowb(true);


    return(
      <>
        <Card  className="paddingPractice" style={{ width: '100%', border: 'none', borderRadius: '0' }}>
        <Card.Body>
          <Card.Title id='contact' style={{fontFamily: 'notoSemibold', fontSize: '1.5em', marginBottom: '4rem'}}>Contact</Card.Title>
          <Row style={{textAlign: 'center'}}>
          <Card.Text style={{fontFamily: 'notoRegular',  fontSize: '1.1em', lineHeight: '2rem'}}>
            If you liked my work, have any questions or simply want to communicate, here is my contact.
          </Card.Text>
          </Row>
          <Row style={{textAlign: 'center', margin: '1rem'}}>
          
          <Card.Text>
          <a target="_blank" rel="noreferrer" href="https://www.instagram.com/lasolcitacosmica/"><Card.Img  style={{width: "2.2rem", alignItems: 'center'}} src={instagram}/></a>
        
         <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/sofia-mato/"><Card.Img style={{width: "2.2rem", alignItems: 'center'}} src={linkedin}/></a>

          <Card.Img onClick={handleShowb} style={{width: "2.2rem", alignItems: 'center', cursor: 'pointer'}} src={otros}/>
          </Card.Text>
  
         
          </Row>
        </Card.Body>
      </Card>
        
        <Modal show={showb} onHide={handleCloseb}>
        <Modal.Header closeButton>
          <Modal.Title style={{fontFamily: 'notoRegular', fontSize: '1.2em'}}>Other media</Modal.Title>
        </Modal.Header>
        <Modal.Body>

      
          <Row style={{textAlign: 'center', margin: '1rem'}}>
          
          <Card.Text style={{fontFamily: 'notoRegular',  fontSize: '1.1em', lineHeight: '2rem'}}>
          
          <Card.Img  style={{width: "1.7rem"}} src={mail}/> sofiamato30@gmail.com
         
          </Card.Text>

          </Row>
         

        </Modal.Body>
        
      </Modal>

      </>
        
        
    );

}

export default Contact;
import './style.css';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
//import React, { useEffect, useState } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';

function Practice() {
//   const [showDivb, setShowDivb] = useState(false);
//   const [showDiv2b, setShowDiv2b] = useState(false);
//   const [showDiv3b, setShowDiv3b] = useState(false);

//   useEffect(() => {
//     const onScroll = () => {
//       const newShowDivb = window.scrollY > 700
//       showDivb !== newShowDivb && setShowDivb(newShowDivb);
//     };
//     window.addEventListener('scroll', onScroll);
//     window.removeEventListener('scroll', onScroll);
//   }, [showDivb]);

//   useEffect(() => {
//     const onScroll = () => {
//       const newShowDiv2b = window.scrollY > 1000
//       showDiv2b !== newShowDiv2b && setShowDiv2b(newShowDiv2b);
//     };
//     window.addEventListener('scroll', onScroll);
//     window.removeEventListener('scroll', onScroll);
//   }, [showDiv2b]);

//   useEffect(() => {
//     const onScroll = () => {
//       const newShowDiv3b = window.scrollY > 1400
//       showDiv3b !== newShowDiv3b && setShowDiv3b(newShowDiv3b);
//     };
//     window.addEventListener('scroll', onScroll);
//     window.removeEventListener('scroll', onScroll);
//   }, [showDiv3b]);

  const CardListb = () => {
    return (
      <Card className="typing-effect" style={{border: 'none', width: '100%', background: '#0a1a2f', padding: '3rem', borderRadius: '0'}}>
        <Card.Body className="typed-text">
      <Card.Text style={{lineHeight: '2rem', fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify'}}>Create React App</Card.Text>
      <Card.Text style={{lineHeight: '2rem', fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify'}}>Material Design</Card.Text>
      <Card.Text style={{lineHeight: '2rem', fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify'}}>ReactJS</Card.Text>
      <Card.Text style={{lineHeight: '2rem', fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify'}}>Javascript</Card.Text>
      </Card.Body>
      </Card>
    )
  }


  const CardList2b = () => {
    return (
      <Card className="typing-effect2" style={{border: 'none', width: '100%', background: '#0a1a2f', padding: '3rem', borderRadius: '0'}}>
      <Card.Body className="typed-text">
      <Card.Text style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem'}}>Create React App</Card.Text>
      <Card.Text style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem'}}>Bootstrap</Card.Text>
      <Card.Text style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem'}}>ReactJS</Card.Text>
      <Card.Text style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem'}}>Javascript</Card.Text>
      <Card.Text style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem'}}>FireBase</Card.Text>
      </Card.Body>
      </Card>
    )
  }

  const CardList3b = () => {
    return (
      <Card className="typing-effect" style={{border: 'none', background: '#0a1a2f', width: '100%', padding: '3rem', borderRadius: '0'}}>
       <Card.Body className="typed-text">
      <Card.Text style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem'}}>NextJS</Card.Text>
      <Card.Text style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem'}}>Server - client integration</Card.Text>
      <Card.Text style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem'}}>styled-components</Card.Text>
      <Card.Text style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem'}}>Apollo graphQL</Card.Text>
      <Card.Text style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem'}}>Typescript</Card.Text>
      <Card.Text style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem'}}>Javascript</Card.Text>
      </Card.Body>
      </Card>
    )
  }
    return(
        <Card className="paddingPractice" style={{ width: '100%', border: 'none', background: '#0a1a2f', borderRadius: '0' }}>
        <Card.Body>
          <Row xs={1} sm={2} md={2} lg={2}>
          <Col style={{marginBottom: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Link to='/etravelEng'><Button variant="light" className="sitio">Quoter</Button></Link>
          </Col>
          <Col style={{marginBottom: '1rem'}}>
            {/* {showDivb && <CardListb /> } */}
            <CardListb />
          </Col>
          </Row>
          <Row xs={1} sm={2} md={2} lg={2}>
          <Col style={{marginBottom: '1rem'}}>
          {/* {showDiv2b && <CardList2b />} */}
          <CardList2b />
          </Col>
          <Col style={{marginBottom: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Link to='/ecommerceEng'><Button variant="light" className="sitio">E - commerce</Button></Link>
          </Col>
          </Row>
          <Row xs={1} sm={2} md={2} lg={2}>
          <Col style={{marginBottom: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Link to='/petappe'><Button variant="light" className="sitio">Like Pet App</Button></Link>
          </Col>
          <Col style={{marginBottom: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          {/* {showDiv3b && <CardList3b />} */}
          <CardList3b />
          </Col>
          </Row>
        </Card.Body>
        </Card>
    )

}

export default Practice;
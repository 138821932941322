
import './style.css';
import AboutMe from './AboutMe';
import NavigationEnglish from './NavBarEnglish';
import Courses from './Courses'
import Practice from './Practice'
import WebSites from './WebSites'
import Contact from './Contact'


function EnglishPage() {
    return(
        <div key={0}>
        <NavigationEnglish />
        <AboutMe/>
        <Courses />
        <Practice />
        <WebSites />
        <Contact />
        </div>
        
   
    );

}

export default EnglishPage;
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import eCommerce1 from './imagenes/eCommerce1.png';
import eCommerce5 from './imagenes/eCommerce5.png';
import eCommerce6 from './imagenes/eCommerce6.png';
import eCommerce7 from './imagenes/eCommerce7.png';
import ListGroup from 'react-bootstrap/ListGroup';
import arrow50 from './imagenes/plus.png';
import { useEffect } from 'react';
import NavigationEnglish from './NavBarEnglish'

function EcommerceEng() {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <>
        <NavigationEnglish />
        <Card className="padding3rem" style={{ background: '#0a1a2f'}}>
        <Card.Title style={{ color: 'white', padding: '2rem', fontFamily: 'notoBold', fontSize: '1.5em'}} id='petgram'>E - commerce simulator</Card.Title>
        <Row className="padding2rem" xs={1} sm={1} md={1} lg={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Card.Img style={{width: '100%', borderRadius: '1.5%'}} src={eCommerce1}></Card.Img>
        </Row>
        <Row className='padding2rem' xs={1} sm={1} md={1} lg={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <ListGroup className='padding2rem' style={{ background: '#0a1a2f' }}>
        <ListGroup.Item style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem', color: 'white', background: '#0a1a2f'}}> <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/>Product list creation</ListGroup.Item>
        <ListGroup.Item style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem', color: 'white', background: '#0a1a2f'}}><Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/>Category creation</ListGroup.Item>
        <ListGroup.Item style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem', color: 'white', background: '#0a1a2f'}}><Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/>Access to the shopping cart from any page of the application</ListGroup.Item>
        </ListGroup>
        </Row>
        <Row className='padding2rem' xs={1} sm={1} md={3} lg={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Col style={{padding: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Card.Img style={{width: '90%'}} src={eCommerce5}></Card.Img>
        </Col>
        <Col style={{padding: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Card.Img style={{width: '90%'}} src={eCommerce6}></Card.Img>
        </Col>
        <Col style={{padding: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Card.Img style={{width: '90%'}} src={eCommerce7}></Card.Img>
        </Col>
        </Row>
        <Row className='padding2rem' xs={1} sm={1} md={1} lg={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <ListGroup className='padding2rem' style={{ background: '#0a1a2f' }}>
        <ListGroup.Item style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem', color: 'white', background: '#0a1a2f'}}> <Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/>Creating alerts</ListGroup.Item>
        <ListGroup.Item style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem', color: 'white', background: '#0a1a2f'}}><Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/>Design fully adaptable to different devices</ListGroup.Item>
        <ListGroup.Item style={{fontFamily: 'notoRegular', fontSize: '1em', textAlign: 'justify', lineHeight: '2rem', color: 'white', background: '#0a1a2f'}}><Card.Img style={{width: "1.1rem", marginRight: '0.5rem'}} src={arrow50}/>Sending purchase data to database</ListGroup.Item>
        </ListGroup>
        </Row>
       </Card>

       </>

    )
    
    }
    
    
    export default EcommerceEng;
import './style.css';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import pic1 from './imagenes/8cuentos3.png';
import pic2 from './imagenes/odontofundas2.png';
import Button from 'react-bootstrap/Button';
import check from './imagenes/check.png';
import Modal from 'react-bootstrap/Modal';
import React, { useState } from 'react';

function AlgunosTrabajos() {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showb, setShowb] = useState(false);
  const handleCloseb = () => setShowb(false);
  const handleShowb = () => setShowb(true);

    return(
      <>
        <Card className="paddingPractice" xs={1} sm={1} md={1} lg={1} style={{ width: '100%', border: 'none', background: '#0a1a2f', borderRadius: '0' }}>
        <Card.Body>
          <Card.Title id='trabajos' style={{ color: 'white', marginBottom: '2rem', fontFamily: 'notoSemibold', fontSize: '1.5em'}}>Algunos trabajos</Card.Title>
          
          <Row xs={1} sm={1} md={1} lg={2}>
          <Col>
          <Card className="CardPadding" style={{ width: '100%', border: 'none', background: '#0a1a2f', borderRadius: '0' }}>
         
          <Card.Img  src={pic1}></Card.Img>
         
       
          <Card.Title id='trabajos' style={{ color: 'white', marginBottom: '1rem', fontFamily: 'notoSemibold', fontSize: '1.3em', marginTop:'1.5rem'}}>Landing Page<Card.Img style={{width: "1.8rem", margin: '0.5rem'}} src={check}/></Card.Title>
          <Row xs={3} sm={3} md={3} lg={3} className='practiceRow' style={{textAlign: 'center', marginTop:'.4rem', width:'100%'}}>
      <Col className='practiceCol'><Button onClick={handleShow} type="button" className="lenguajes">Lenguajes</Button></Col>
      <Col className='practiceCol'><a target="_blank" rel="noreferrer" href="https://getbootstrap.com/"><Button type="button" variant="outline-light" className='framework1a'>Framework</Button></a></Col>
      <Col className='practiceCol'><a target="_blank" rel="noreferrer" href="https://ochocuentos.com/"><Button variant="light" className="sitio">Ir al sitio</Button></a></Col>
       </Row>
      

          </Card>
          </Col>
          <Col>
          <Card className="CardPadding" style={{ width: '100%', border: 'none', background: '#0a1a2f', borderRadius: '0' }}>
         
        <Card.Img  src={pic2}></Card.Img>
         
    
          <Card.Title id='trabajos' style={{ color: 'white', marginBottom: '1rem', fontFamily: 'notoSemibold', fontSize: '1.3em', marginTop:'1.5rem'}}>E - commerce<Card.Img style={{width: "1.8rem", margin: '0.5rem'}} src={check}/></Card.Title>
         
          <Row xs={3} sm={3} md={3} lg={3} className='practiceRow' style={{textAlign: 'center', marginTop:'.4rem', width:'100%'}}>
      <Col className='practiceCol'><Button onClick={handleShowb} type="button" className="lenguajes">Lenguajes</Button></Col>
      <Col className='practiceCol'><a  target="_blank" rel="noreferrer" href="https://www.tiendanube.com/"><Button type="button" variant="outline-light" className='framework1a'>Plataforma</Button></a></Col>
      <Col className='practiceCol'><a  target="_blank" rel="noreferrer" href="https://www.odontofundas.com.ar/"><Button variant="light" className="sitio">Ir al sitio</Button></a></Col>
       </Row>

          </Card>
          </Col>
          </Row>
        </Card.Body>
      </Card>
        
        <Modal show={show} onHide={handleClose}>
<Modal.Header closeButton>
  
</Modal.Header>
<Modal.Body >
<Row xs={1} sm={1} md={1} lg={1} style={{textAlign: 'center', marginTop:'.4rem', width:'100%'}}>
      <Col style={{marginBottom: '1rem'}}><Card.Text style={{ fontFamily: 'notoSemibold', background: 'transparent', fontSize: '1rem'}} >HTML</Card.Text></Col>
      <Col style={{marginBottom: '1rem'}}><Card.Text style={{ fontFamily: 'notoSemibold', background: 'transparent', fontSize: '1rem'}} >CSS</Card.Text></Col>
      <Col style={{marginBottom: '1rem'}}><Card.Text style={{ fontFamily: 'notoSemibold', background: 'transparent', fontSize: '1rem'}} >Javascript</Card.Text></Col>

    </Row>

</Modal.Body>
</Modal>

<Modal show={showb} onHide={handleCloseb}>
<Modal.Header closeButton>
  
</Modal.Header>
<Modal.Body >
<Row xs={1} sm={1} md={1} lg={1} style={{textAlign: 'center', marginTop:'.4rem', width:'100%'}}>
      <Col style={{marginBottom: '1rem'}}><Card.Text style={{ fontFamily: 'notoSemibold', background: 'transparent', fontSize: '1rem'}} >HTML</Card.Text></Col>
      <Col style={{marginBottom: '1rem'}}><Card.Text style={{ fontFamily: 'notoSemibold', background: 'transparent', fontSize: '1rem'}} >CSS</Card.Text></Col>
      <Col style={{marginBottom: '1rem'}}><Card.Text style={{ fontFamily: 'notoSemibold', background: 'transparent', fontSize: '1rem'}} >Javascript</Card.Text></Col>

    </Row>

</Modal.Body>

</Modal>
       </>
    );

}

export default AlgunosTrabajos;
import './style.css';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import fotocarnet from './imagenes/fotocarnet.png';


function AboutMe() {
    return(
        <Card  className="paddingPractice" style={{  width: '100%', border: 'none', borderRadius: '0'}}>
        <Card.Body>
          <Card.Title style={{fontFamily: 'notoSemibold', fontSize: '1.5em'}} id='aboutme'>About me</Card.Title>
          <Row xs={1} sm={1} md={2} lg={2}>
            <Col>
          <Card className="SobreMiContainer" style={{border: 'none', width: '100%', borderRadius: '0'}}>
            <Card.Text className="sobreMi">
            My name is Sofia Mato, I am a geographer and I have been studying web design, web development and programming for a long time. My interests are the didactics of Geography, which I currently teach, and the development of digital products, in which I began to train and wish to develop.
I did my first web design and development work for a documentary film, together with a graphic designer, which helped me improve my design experience and knowledge.

            </Card.Text>
            </Card>
            </Col>
            <Col>
            <Card className="ImgContainer" style={{ border: 'none', borderRadius: '0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Card.Img className="ImgWidth" src={fotocarnet}></Card.Img>
            </Card>
            </Col>
            </Row>
        </Card.Body>
      </Card>
        
        
    );

}

export default AboutMe;
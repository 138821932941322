import './components/style.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CurriculumCV from './components/CurriculumCV';
import Portada from './components/Principal';
import PetApp from './components/PetApp';
import EnglishPage from './components/EnglishPage';
import Resume from './components/Resume';
import PetAppEnglish from './components/PetAppEnglish';
import Ecommerce from './components/Ecommerce';
import EcommerceEng from './components/EcommerceEng';
import Etravel from './components/Etravel';
import EtravelEng from './components/EtravelEng';


function App() {


  return (

  
    <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<Portada />} />
      <Route exact path="/curriculum" element={<CurriculumCV />} />
      <Route exact path="/petapp" element={<PetApp />} />
      <Route exact path="/ecommerce" element={<Ecommerce />} />
      <Route exact path="/etravel" element={<Etravel />} />
      <Route exact path="/etravelEng" element={<EtravelEng />} />
      <Route exact path="/ecommerceEng" element={<EcommerceEng />} />
      <Route exact path="/english" element={<EnglishPage />} />
      <Route exact path="/resume" element={<Resume />} />
      <Route exact path="/petappe" element={<PetAppEnglish />} />
    </Routes>
  </BrowserRouter>
  
  );
}

export default App;

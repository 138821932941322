import img1 from './imagenes/photo.png';
import img2 from './imagenes/UX.png';
import img3 from './imagenes/UXAvanzado.png';
import img4 from './imagenes/web.png';
import img5 from './imagenes/javascript.png';
import img6 from './imagenes/react.png';
import img7 from './imagenes/QGIS.png';
import img8 from './imagenes/WebMapping.png';
import img9 from './imagenes/CertPhotoshop.png';
import img10 from './imagenes/CertUX.png';
import img11 from './imagenes/CertUXAvanzado.png';
import img12 from './imagenes/CertWeb.png';
import img13 from './imagenes/CertJavasc.png';
import img14 from './imagenes/CertReact.png';
import img15 from './imagenes/CertQGISII.png';
import img16 from './imagenes/CertWebMapping.png';
import img17 from './imagenes/CertQGISIII.png';
import img18 from './imagenes/reactAvanzado.png';

const DataFromBD = [

    {
        id: 0,
        curso: "Web Mapping",
        img: img8,
        certificado: img16

    },

    {
        id: 1,
        curso: "QGIS II",
        img: img7,
        certificado: img15

    },

    {
        id: 2,
        curso: "QGIS III",
        img: img7,
        certificado: img17

    },

    {
        id: 3,
        curso: "Photoshop e Illustrator",
        img: img1,
        certificado: img9

    },

    {
        id: 4,
        curso: "Diseño UX/UI",
        img: img2,
        certificado: img10

    },

    {
        id: 5,
        curso: "Diseño UX/UI Avanzado",
        img: img3,
        certificado: img11

    },

    {
        id: 6,
        curso: "Desarrollo Web",
        img: img4,
        certificado: img12

    },

    {
        id: 7,
        curso: "Javascript",
        img: img5,
        certificado: img13

    },

    {
        id: 8,
        curso: "React JS",
        img: img6,
        certificado: img14

    },

    {
        id: 9,
        curso: "React Avanzado",
        img: img6,
        certificado: img18

    }
]

export default DataFromBD;
       
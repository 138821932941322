import './style.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import React from 'react';
import flagSpain from './imagenes/spain.png'


function NavigationEnglish() {

    return(
        <Navbar expand="lg">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
            <Nav.Link style={{fontFamily: 'notoRegular'}} as={HashLink} to='/english#aboutme'>About me</Nav.Link>
              <Nav.Link style={{fontFamily: 'notoRegular'}} as={HashLink} to='/english#courses'>Courses</Nav.Link>
              <Nav.Link style={{fontFamily: 'notoRegular'}} as={HashLink} to='/english#websites'>Development</Nav.Link>
              <Nav.Link as={Link} to='/resume' style={{fontFamily: 'notoRegular'}}>Resume</Nav.Link>
               <Nav.Link style={{fontFamily: 'notoRegular'}} as={HashLink} to='/english#contact'>Contact</Nav.Link>
               <Nav.Link style={{fontFamily: 'notoRegular'}}> </Nav.Link>
               <Nav.Link style={{fontFamily: 'notoSemiBold', fontSize: '.7em', border: '3px solid #0a1a2f', display:'flex', justifyContent: 'center', alignItems: 'center', height:'2rem', color: 'white', backgroundColor: '#0a1a2f', width: '6rem'}} as={Link} to='/'>SPANISH<img src={flagSpain} width="30%" alt="flag"/></Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
        
        
    );

}

export default NavigationEnglish;